import { LazyExoticComponent, FC, lazy } from 'react';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';
import { Route } from '../../../layouts/LayoutRoute';

/**
 * 조직 관리
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const OrganizationSetting: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/OrganizationSetting'));

/** 조직관리 라우팅 */
export const organizationSettingRoute: Route[] = [
  {
    path: AdminNavigatePath.ORGANIZATION_SETTING,
    element: <OrganizationSetting />,
    roles: [],
    isAuth: false,
  },
];
