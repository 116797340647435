import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { memo, ReactNode } from 'react';
import { theme } from '../../../ThemeConfig';

/**
 * 커스텀 테마 제공 소품
 */
interface ThemeProviderProps {
  /**
   * react node
   */
  children: ReactNode;
}

/**
 * 메타리얼 캐시 설정
 */
const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

/**
 * 커스텀 테마 제공
 *
 * @param props 소품
 */
const ThemeProvider = (props: ThemeProviderProps) => {
  /**
   * 소품
   */
  const { children } = props;

  return (
    <CacheProvider value={muiCache}>
      <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
    </CacheProvider>
  );
};

export default memo(ThemeProvider);
