import { FC, lazy, LazyExoticComponent } from 'react';
import { Route } from '../../../layouts/LayoutRoute';
import { CommonNavigatePath } from '../../../layouts/common/constants/CommonNavigatePath';

// eslint-disable-next-line @typescript-eslint/ban-types
const Login: LazyExoticComponent<FC<{}>> = lazy(() => import('./Login'));

/**
 * 로그인
 */
export const loginRoute: Route[] = [
  {
    path: CommonNavigatePath.LOGIN,
    element: <Login />,
    roles: [],
    isAuth: false,
  },
];
