import { LazyExoticComponent, FC, lazy } from 'react';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';
import { Route } from '../../../layouts/LayoutRoute';

/**
 * 역량템플릿
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const TemplateCompetency: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/TemplateCompetency'));

/** 역량템플릿 라우팅 */
export const templateCompetencyRoute: Route[] = [
  {
    path: AdminNavigatePath.TEMPLATE_COMPETENCY,
    element: <TemplateCompetency />,
    roles: [],
    isAuth: false,
  },
];
