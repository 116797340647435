export enum AdminNavigatePath {
  /**
   * 접두사
   */
  PRE_FIX = 'admin',
  /**
   * 홈
   */
  HOME = 'home',
  /**
   * 조직 관리
   */
  ORGANIZATION_SETTING = 'organization-setting',
  /**
   * 사용자 관리 / 구성원
   */
  USER_SETTING_EMPLOYEE = 'user-setting/employee',
  /**
   * 사용자 관리 / 사용자
   */
  USER_SETTING_USER = 'user-setting/user',
  /**
   * 역량사전
   */
  DICTIONARY_COMPETENCY = 'dictionary-competency',
  /**
   * 역량템플릿
   */
  TEMPLATE_COMPETENCY = 'template-competency',
  /**
   * 평가환산표
   */
  EVALUATION_CONVERSION = 'evaluation-conversion',
  /**
   * 평가관리
   */
  EVALUATION_MANAGEMENT = 'evaluation-management',
}
