import { LazyExoticComponent, FC, lazy } from 'react';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';
import { Route } from '../../../layouts/LayoutRoute';

/**
 * 평가환산표
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const EvaluationConversion: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/EvaluationConversion'));

/** 평가환산표 라우팅 */
export const evaluationConversionRoute: Route[] = [
  {
    path: AdminNavigatePath.EVALUATION_CONVERSION,
    element: <EvaluationConversion />,
    roles: [],
    isAuth: false,
  },
];
