import { RecoilRoot } from 'recoil';
import ErrorSuspense from './components/molecule/error-suspense/ErrorSuspense';
import { LayoutRoute } from './layouts/LayoutRoute';
import { QueryClientProvider } from './utils/provider/query-client/QueryClientProvider';
import ThemeProvider from './utils/provider/theme/ThemeProvider';

function App() {
  return (
    <RecoilRoot>
      <QueryClientProvider>
        <ThemeProvider>
          <ErrorSuspense>
            <LayoutRoute />
          </ErrorSuspense>
        </ThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
