import { LazyExoticComponent, FC, lazy } from 'react';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';
import { Route } from '../../../layouts/LayoutRoute';

/**
 * 평가관리
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const EvaluationManagement: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/EvaluationManagement'));
/** 평가관리 목록 */
// eslint-disable-next-line @typescript-eslint/ban-types
const EvaluationManagementList: LazyExoticComponent<FC<{}>> = lazy(
  () => import('./entrypoint/molecule/EvaluationManagementList'),
);
/** 평가관리 목록 */
// eslint-disable-next-line @typescript-eslint/ban-types
const EvaluationPlanDetail: LazyExoticComponent<FC<{}>> = lazy(() => import('./detail/EvaluationPlanDetail'));

/** 평가관리 라우팅 */
export const evaluationManagementRoute: Route[] = [
  {
    path: AdminNavigatePath.EVALUATION_MANAGEMENT,
    element: <EvaluationManagement />,
    roles: [],
    isAuth: false,
    children: [
      {
        path: '',
        element: <EvaluationManagementList />,
        roles: [],
        isAuth: false,
      },
      {
        path: ':planNo',
        element: <EvaluationPlanDetail />,
        roles: [],
        isAuth: false,
      },
    ],
  },
];
