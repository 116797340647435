import { LazyExoticComponent, FC, lazy } from 'react';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';
import { Route } from '../../../layouts/LayoutRoute';

/**
 * 사용자관리
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const UserSetting: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/UserSetting'));
// eslint-disable-next-line @typescript-eslint/ban-types
const Employee: LazyExoticComponent<FC<{}>> = lazy(() => import('./employee/Employee'));
// eslint-disable-next-line @typescript-eslint/ban-types
const User: LazyExoticComponent<FC<{}>> = lazy(() => import('./user/User'));

/** 사용자관리 라우팅 */
export const userSettingRoute: Route[] = [
  {
    element: <UserSetting />,
    roles: [],
    isAuth: false,
    children: [
      {
        path: AdminNavigatePath.USER_SETTING_EMPLOYEE,
        element: <Employee />,
        roles: [],
        isAuth: false,
      },
      {
        path: AdminNavigatePath.USER_SETTING_USER,
        element: <User />,
        roles: [],
        isAuth: false,
      },
    ],
  },
];
