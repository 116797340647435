import { LazyExoticComponent, FC, lazy } from 'react';
import { Route } from '../LayoutRoute';
import { loginRoute } from '../../pages/common/login/LoginRoute';
import { CommonNavigatePath } from './constants/CommonNavigatePath';

// eslint-disable-next-line @typescript-eslint/ban-types
const CommonLayout: LazyExoticComponent<FC<{}>> = lazy(() => import('./CommonLayout'));

export const commonRoute: Route[] = [
  {
    path: CommonNavigatePath.LOGIN,
    element: <CommonLayout />,
    roles: [],
    isAuth: false,
    children: [...loginRoute],
  },
];
