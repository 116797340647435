import { PaletteOptions, createTheme } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const basicPalette: PaletteOptions = {
  black: { main: '#000000' },
  white: { main: '#FFFFFF' },
  primary: { main: '#3949AB' },
  secondary: { main: '#071F60' }, // indigo600
  secondaryLight: { main: '#5C6BC0' },
  indigo50: { main: '#E8EAF6' },
  indigo100: { main: '#C5CAE9' },
  indigo300: { main: '#7986CB' },
  indigo600: { main: '#3949AB' },
  pink50: { main: '#FCE4EC' },
  pink500: { main: '#E91E63' },
  pink600: { main: '#D81B60' },
  red100: { main: '#FFCDD2' },
  red900: { main: '#B71C1C' },
  green50: { main: '#E8F5E9' },
  green100: { main: '#C8E6C9' },
  green600: { main: '#43A047' },
  green900: { main: '#1B5E20' },
  grey50: { main: '#FAFAFA' },
  grey100: { main: '#F5F5F5' },
  grey200: { main: '#EEEEEE' },
  grey300: { main: '#E0E0E0' },
  grey400: { main: '#BDBDBD' }, // outlineVariant
  grey600: { main: '#757575' },
  grey700: { main: '#616161' },
  grey800: { main: '#424242' },
  grey900: { main: '#212121' },
  amber50: { main: '#FFF8E1' },
  amber200: { main: '#FFE082' },
  primaryOpacity10: { main: 'rgba(57, 73, 171, 0.08)' },
  primaryOpacity20: { main: 'rgba(57, 73, 171, 0.12)' },
  primaryOpacity30: { main: 'rgba(57, 73, 171, 0.16)' },
  greyOpacity10: { main: 'rgba(21, 21, 21, 0.08)' },
  greyOpacity20: { main: 'rgba(21, 21, 21, 0.12)' },
  greyOpacity30: { main: 'rgba(21, 21, 21, 0.16)' },
};

export const defaultFont = {
  fontStyle: 'normal',
  lineHeight: '148%',
  letterSpacing: '-0.018em',
  color: 'currentColor',
  fontFamily: 'Pretendard',
};

export const logoFont = {
  fontStyle: 'normal',
  color: 'currentColor',
  fontFamily: `futura-pt-condensed`,
};

export const theme = createTheme({
  palette: {
    ...basicPalette,
    error: {
      light: '#e57373',
      main: '#D3322F',
      dark: '#B71C1C',
    },
    success: {
      light: '#81C784',
      main: '#2E7D32',
      dark: '#000',
    },
    warning: {
      light: '#000',
      main: '#ED6C02',
      dark: '#000',
    },
    info: {
      main: '#0288D1',
    },
  },
  spacing: 4,
  typography: {
    ...logoFont,
    ...defaultFont,
    htmlFontSize: 16,
    displayLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '3.5rem',
    },
    displayMedium: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '2.75rem',
    },
    displaySmall: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '2.25rem',
    },
    headlineLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '2rem',
    },
    headlineMedium: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1.75rem',
    },
    headlineSmall: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1.5rem',
    },
    titleLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1.375rem',
    },
    titleMedium: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '1rem',
      letterSpacing: 0.15,
    },
    titleSmall: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: 0.1,
    },
    bodyLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: 0.5,
    },
    bodyMedium: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '0.875rem',
      letterSpacing: 0.25,
    },
    bodySmall: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: 0.4,
    },
    labelLargeP: {
      ...defaultFont,
      fontWeight: 600,
      fontSize: '0.875rem',
      letterSpacing: 0.1,
    },
    labelLarge: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: 0.1,
    },
    labelMediumP: {
      ...defaultFont,
      fontWeight: 600,
      fontSize: '0.75rem',
      letterSpacing: 0.5,
    },
    labelMedium: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.75rem',
      letterSpacing: 0.5,
    },
    labelSmall: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.6875rem',
      letterSpacing: 0.5,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1024,
      xl: 1536,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: () => ({
          minHeight: 0,
          minWidth: 0,
          marginRight: theme.spacing(2),
          padding: theme.spacing(1.5, 4),
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.indigo300.main}`,
          borderRadius: theme.spacing(2),
          textTransform: 'none',
          '&.Mui-selected': {
            background: theme.palette.indigo50.main,
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          minHeight: 40,
          display: 'flex',
          alignItems: 'center',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: () => ({
          height: 'calc(100% - 32px)',
          background: theme.palette.white.main,
          boxShadow: `0px 0px 10px 0px ${theme.palette.greyOpacity10.main}`,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.primary.main,
          margin: theme.spacing(0, 4),
          marginBottom: theme.spacing(2),
          borderRadius: theme.spacing(2),
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: () => ({
          '& .MuiTypography-root': {
            ...theme.typography.titleMedium,
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: () => ({
          minWidth: 0,
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: () => ({
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2, 0),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: () => ({
          '& fieldset': {
            borderRadius: theme.spacing(2),
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => ({
          lineHeight: 0,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          margin: 0,
          ...theme.typography.bodySmall,
        }),

        error: () => ({
          color: theme.palette.error.main,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: () => ({
          '& fieldset': {
            borderRadius: theme.spacing(2),
          },
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: () => ({
          gap: theme.spacing(1),
          padding: theme.spacing(1),
          background: theme.palette.indigo50.main,
          borderRadius: theme.spacing(3),
          overflow: 'hidden',
        }),
        grouped: () => ({
          height: 32,
          width: 96,
          border: 0,
          padding: 0,
          borderRadius: theme.spacing(2),
          '&.Mui-selected': {
            gap: theme.spacing(2),
            color: theme.palette.secondary.main,
            background: theme.palette.white.main,
            boxShadow: `0px 0px 10px 0px ${theme.palette.greyOpacity10.main}`,
            pointerEvents: 'none',
          },
          '&:hover': {
            background: theme.palette.primaryOpacity10.main,
          },
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: () => ({
          border: 0,
        }),
        columnHeader: () => ({
          background: theme.palette.grey50.main,
          borderTop: `1px solid ${theme.palette.grey400.main} !important`,
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        }),
        virtualScroller: () => ({
          borderRadius: '0 !important',
        }),
        row: () => ({
          background: theme.palette.white.main,
        }),
        cell: () => ({
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        }),
        withBorderColor: () => ({
          border: 0,
          borderTop: `1px solid ${theme.palette.grey400.main}`,
        }),
        footerContainer: () => ({
          border: `1px solid ${theme.palette.error.main}`,
        }),
        menu: () => ({
          borderRadius: theme.spacing(2),
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.24)',
          overflow: 'hidden',
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: () => ({
          width: '100%',
          marginTop: theme.spacing(6),
        }),
        toolbar: () => ({
          justifyContent: 'center',
          padding: '0 !important',

          '& .MuiPagination-text': {
            margin: 0,
          },
        }),
        spacer: () => ({
          display: 'none',
        }),
        displayedRows: () => ({
          display: 'none',
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: () => ({
          borderRadius: theme.spacing(3),
          maxWidth: 'none',
          boxShadow: 'none',
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          padding: theme.spacing(6, 9),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',

          '& .MuiTypography-root': {
            ...theme.typography.titleLarge,
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: () => ({
          borderTop: `1px solid ${theme.palette.grey300.main}`,
          padding: theme.spacing(9),
          paddingTop: `${theme.spacing(7)} !important`,
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: () => ({
          padding: `0 !important`,
          maxHeight: '25rem',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          minWidth: 0,
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: () => ({
          ...theme.typography.bodyLarge,
        }),
        iconContainer: () => ({
          paddingRight: theme.spacing(4),
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: () => ({
          fontSize: 20,
        }),
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: () => ({
          paddingLeft: theme.spacing(6),
          ...theme.typography.bodySmall,
          color: theme.palette.grey600.main,
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: () => ({
          borderRadius: theme.spacing(2),
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.24)',
        }),
        listbox: () => ({
          '& li': {
            height: 40,
            paddingLeft: 0,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: () => ({
          borderRadius: theme.spacing(2),
          '&.MuiPaper-outlined': {
            border: `1px solid ${theme.palette.grey400.main}`,
          },
        }),
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: () => ({
          gap: theme.spacing(3),
        }),
      },
    },
  },
});
