import { LazyExoticComponent, FC, lazy } from 'react';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';
import { Route } from '../../../layouts/LayoutRoute';

/**
 * 어드민 홈
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const Home: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/Home'));

/** 어드민 홈 라우팅 */
export const homeRoute: Route[] = [
  {
    path: AdminNavigatePath.HOME,
    element: <Home />,
    roles: [],
    isAuth: false,
  },
];
