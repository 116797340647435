import { FC, LazyExoticComponent, lazy } from 'react';
import { Route } from '../../../layouts/LayoutRoute';
import { AdminNavigatePath } from '../../../layouts/admin/constants/AdminNavigatePath';

/**
 * 역량 사전
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const DictionaryCompetency: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/DictionaryCompetency'));
/** 역량사전 목록 */
// eslint-disable-next-line @typescript-eslint/ban-types
const DictionaryCompetencyList: LazyExoticComponent<FC<{}>> = lazy(
  () => import('./entrypoint/organism/DictionaryCompetencyList'),
);
/** 역량사전 상세 */
// eslint-disable-next-line @typescript-eslint/ban-types
const DictionaryCompetencyDetail: LazyExoticComponent<FC<{}>> = lazy(
  () => import('./entrypoint/organism/DictionaryCompetencyDetail'),
);

/** 역량사전 라우팅 */
export const dictionaryCompetencyRoute: Route[] = [
  {
    path: AdminNavigatePath.DICTIONARY_COMPETENCY,
    element: <DictionaryCompetency />,
    roles: [],
    isAuth: false,
    children: [
      {
        path: '',
        element: <DictionaryCompetencyList />,
        roles: [],
        isAuth: false,
      },
      {
        path: ':no',
        element: <DictionaryCompetencyDetail />,
        roles: [],
        isAuth: false,
      },
    ],
  },
];
