import { LazyExoticComponent, FC, lazy } from 'react';
import { Route } from '../LayoutRoute';
import { homeRoute } from '../../pages/admin/home/HomeRoute';
import { organizationSettingRoute } from '../../pages/admin/organization-setting/OrganizationSettingRoute';
import { dictionaryCompetencyRoute } from '../../pages/admin/dictionary-competency/DictionaryCompetencyRoute';
import { templateCompetencyRoute } from '../../pages/admin/template-competency/TemplateCompetencyRoute';
import { evaluationConversionRoute } from '../../pages/admin/evaluation-conversion/EvaluationConversionRoute';
import { evaluationManagementRoute } from '../../pages/admin/evaluation-management/EvaluationManagementRoute';
import { userSettingRoute } from '../../pages/admin/user-setting/UserSettingRoute';
import { AdminNavigatePath } from './constants/AdminNavigatePath';

// eslint-disable-next-line @typescript-eslint/ban-types
const AdminLayout: LazyExoticComponent<FC<{}>> = lazy(() => import('./AdminLayout'));

/**
 * 어드민 라우트 목록
 */
export const adminRoute: Route[] = [
  {
    path: AdminNavigatePath.PRE_FIX,
    element: <AdminLayout />,
    roles: [],
    isAuth: false,
    children: [
      ...homeRoute,
      ...organizationSettingRoute,
      ...userSettingRoute,
      ...dictionaryCompetencyRoute,
      ...templateCompetencyRoute,
      ...evaluationConversionRoute,
      ...evaluationManagementRoute,
    ],
  },
];
